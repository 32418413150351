import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Davis({ styles, ...props }) {
  const Elements = useComponents()
  const pattern_color = styles.forElement("pattern_one").backgroundColor

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div
        className="formkit-background"
        style={{ backgroundColor: pattern_color }}
      />
      <div className="formkit-wrapper">
        <div className="formkit-header" role="banner">
          <Elements.Heading
            className="formkit-heading"
            name="heading"
            defaults={{
              content: "Get The Latest Tech News & Reviews."
            }}
          />
          <div className="formkit-underline" />
          <Elements.Video
            id="video"
            name="video"
            className="formkit-video"
            defaults={{
              src: "https://www.youtube.com/watch?v=pyPPK9CoOD8"
            }}
          />
          <Elements.Region className="formkit-content" name="content">
            <Elements.Content
              defaults={{
                content:
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }}
            />
          </Elements.Region>
        </div>
        <div
          className="formkit-content-container"
          style={styles.forElement("form_background")}
          role="main"
        >
          <Elements.Image
            className="formkit-form-image"
            name="image"
            defaults={{
              src:
                "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/gdQpZW9BTmi1fgqmmn39o8"
            }}
            size={{ w: 50, h: 50 }}
          />
          <Elements.Heading
            className="formkit-form-heading"
            name="form_heading"
            tag="h2"
            defaults={{
              content: "Join The Mailing List..."
            }}
          />
          <Elements.Region className="formkit-form-content" name="form_content">
            <Elements.Content
              defaults={{
                content:
                  "Sign up now and receive exclusive content on new tech launches and tips for different apps or devices."
              }}
            />
          </Elements.Region>
          <Elements.Form>
            <Elements.Errors />
            <Elements.CustomFields>
              <Elements.AddFieldButton />
              <Elements.Button
                name="button"
                group="button"
                defaults={{ content: "Submit" }}
              />
            </Elements.CustomFields>
          </Elements.Form>
          <Elements.Content
            className="formkit-disclaimer"
            name="disclaimer"
            defaults={{
              content:
                "<p>We respect your privacy. Unsubscribe at any time.</p>"
            }}
          />
          <Elements.BuiltWith background="form_background" />
        </div>
      </div>
    </Elements.LandingPage>
  )
}

export default createTemplate(Davis, { name: "Davis" })
